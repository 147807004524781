window.Hammer = require("../vendor/hammer");
require("../vendor/tweenmax");


jQuery(document).ready(function () {
    window["$"] = jQuery;

    window.is_touch_device = function () {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    };

    require("../ui/helpers");
    require("../ui/overlayMenu");
    require("../ui/imageSize");
    require("../ui/mouse");
    require("../ui/filmstrip");
    require("../ui/loader");
    require("../ui/select");
    require("../ui/accordion");
    require("../ui/scrollableFilmstrip");
    require("../ui/dialog");
    require("../ui/tabs");
    require("../ui/slider");
    require("../ui/siteScroll");
    require("../ui/siteScroll");
    require("../ui/calendar");
    require("../ui/datePicker");
    $(".ui-filmstrip").uiFilmstrip();
    $(".ui-select").uiSelect();
    $(".ui-accordion").uiAccordion();
    $(".ui-tabs").uiTabs();
    $(".ui-slider").uiSlider({v: 2});
    $(".ui-siteScroll").uiSiteScroll();
    $(".ui-datePicker").uiDatePicker();
    if (is_touch_device()) {
        if ($(window).width() < 600 && false) {
            $(".ui-scrollableFilmstrip").uiScrollableFilmstrip({mode: 2, hover: false, boxScroll: false});
        } else {
            $(".ui-scrollableFilmstrip").uiScrollableFilmstrip({mode: 2, hover: false});
        }
    } else {
        $(".ui-scrollableFilmstrip").uiScrollableFilmstrip({mode: 2});
    }

    $(".ui-folding").each(function () {
        var $n = $(this);
        var top1 = 0;
        var $body = $("body");

        function menuSet() {
            var top = $(window).scrollTop();
            $n.removeClass("ui-folding-scrolled ui-folding-opened");
            if (top > 0) {
                $n.addClass("ui-folding-scrolled");
            }
            if (top < top1) {
                $n.addClass("ui-folding-opened");
            }
            top1 = top;
        }

        $(".ui-folding-icon").on("click", function () {
            if ($n.hasClass("ui-folding-opened")) {
                $n.removeClass("ui-folding-opened");
            } else {
                $n.addClass("ui-folding-opened");
            }
        });
        $n.addClass("destroy").on({
            destroy: function () {
                $(window).off({
                    scroll: menuSet,
                    resize: menuSet
                })
            }
        });

        setTimeout(menuSet, 10);
        $(window).on({
            scroll: menuSet,
            resize: menuSet
        })
    });
});                                                                                   